import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ADMIN_ROLE_ID } from '../config';
import { GET_ME } from '../graphql';

const TOKEN_NAME = 'jwt';

let authToken;
export const getAuthToken = () => {
  return authToken;
};
export const logout = () => {
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem('LOCATIONFILTER');
};
// custom hook to handle authToken - we use composition to decouple the auth system and its storage
export const useAuth = () => {
  try {
    authToken = JSON.parse(localStorage.getItem(TOKEN_NAME));
    const { data, loading: loadingGetUser } = useQuery(GET_ME);

    // this function allows to save any string in our cookies, under the key "jwt"
    const setAuthToken = (newAuthToken) => {
      authToken = newAuthToken;
      localStorage.setItem(TOKEN_NAME, JSON.stringify(authToken));
    };

    return {
      authToken,
      setAuthToken,
      logout,
      currentUser: data?.me,
      loadingAuth: loadingGetUser,
      isAdmin: data?.me?.role?.id === ADMIN_ROLE_ID,
    };
  } catch (err) {
    console.log('useAuth err :>> ', err);
    const logout = () => {};

    // this function allows to save any string in our cookies, under the key "jwt"
    const setAuthToken = () => {};
    return {
      authToken,
      setAuthToken,
      logout,
      currentUserId: null,
    };
  }
};

export const extractApolloErrorMessage = (error) => {
  return (
    error?.graphQLErrors?.[0]?.extensions?.exception?.data?.data?.[0]
      ?.messages?.[0]?.id ??
    error?.message ??
    error?.[0]?.message ??
    error?.graphQLErrors?.[0]?.message ??
    error?.networkError?.message ??
    'DEFAULT_ERROR' +
      (error?.operation?.operationName
        ? ' ' + error.operation.operationName
        : '')
  );
};

export const AuthorizedRoute = React.memo(
  ({
    redirectIfNotAuthorized = '/',
    path,
    exact = false,
    strict = false,
    skipRedirect = false,
    ...routeProps
  }) => {
    const { authToken } = useAuth();
    const renderRoute = React.useCallback(
      ({ location }) => {
        if (
          ((!authToken && location.pathname !== '/') ||
            (authToken && location.pathname === '/')) &&
          !skipRedirect
        ) {
          return (
            <Redirect
              to={{
                pathname: redirectIfNotAuthorized,
                state: { from: location },
              }}
            />
          );
        }
        return (
          <Route exact={exact} path={path} strict={strict} {...routeProps} />
        );
      },
      [exact, path, redirectIfNotAuthorized, routeProps, strict, authToken],
    );
    return (
      <Route exact={exact} path={path} render={renderRoute} strict={strict} />
    );
  },
);
