import React from 'react';
import { Form, useFormikContext } from 'formik';
import styled from 'styled-components';

import { useAuth } from '../../auth/index.js';
import Button from '../common/Button.js';
import MarkDownTextInput from '../common/input/markDownTextInput.js';
import SelectInput from '../common/input/select.js';
import TextInput from '../common/input/textInput.js';
import { allMessageTypes, messageTypes } from './index.jsx';

const NewMessageContainer = styled.div.attrs({ 'data-color-mode': 'light' })`
  min-width: 80%;
`;
const NewMessageTitle = styled.h2`
  margin-top: 0;
`;

export const NewMessageFormContent = ({ loading, editing }) => {
  const { submitForm } = useFormikContext();
  const { isAdmin } = useAuth();

  return (
    <Form>
      <NewMessageContainer>
        <NewMessageTitle>
          {editing ? 'BERICHT AANPASSEN:' : 'NIEUW BERICHT:'}
        </NewMessageTitle>
        <SelectInput
          name="type"
          label="Type"
          values={isAdmin ? allMessageTypes : messageTypes}
          defaultDisabled
          disabled={editing}
        />
        <TextInput name="title" label="Titel" />
        <MarkDownTextInput name="richBody" label="Bericht" />
      </NewMessageContainer>
      <Button primary onClick={submitForm} loading={loading}>
        Verzenden
      </Button>
    </Form>
  );
};
