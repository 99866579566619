import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { extractApolloErrorMessage, useAuth } from '../auth';
import { LOGIN_IMG_URL, REACT_APP_API_ADMIN_LOCATION } from '../config';
import { POST_LOGIN } from '../graphql/index.js';

const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { setAuthToken, logout } = useAuth();

  const { state } = useLocation();
  const [login, { loading: loadingLogin }] = useMutation(POST_LOGIN, {
    onCompleted: (data) => {
      setAuthToken(data.login.jwt);
      window.location.href = state?.from?.pathname ?? '/bedelingen';
    },
    onError: (error) => {
      setLoading(false);
      const errorCode = extractApolloErrorMessage(error);
      switch (errorCode) {
        case 'Auth.form.error.invalid':
          setError('ERROR: bad username or password');
          break;
        case 429:
          setError('ERROR: too many tries, wait a minute and retry');
          break;
        case 'Failed to fetch':
          setError('ERROR: network error, please contact Admin');
          break;
        default:
          setError(`ERROR: unknown error (${errorCode}), please contact Admin`);
          logout();
      }
    },
  });

  const _Login = React.useCallback(
    (ev) => {
      setLoading(true);
      const identifier = document.getElementById('username').value;
      const password = document.getElementById('password').value;
      ev.preventDefault();
      setError(null);
      login({
        variables: {
          input: { identifier: identifier.toLowerCase(), password },
        },
      });
    },
    [login],
  );

  return (
    <div>
      <section className="frontwrapper">
        <img id="login_banner" src={LOGIN_IMG_URL} alt="dvb logo" />
        <div className="login-page">
          <div className="form">
            <form className="login-form" onSubmit={_Login}>
              <div className="errormsg">{error ? error : null}</div>
              <label htmlFor="username">username</label>
              <input
                id="username"
                type="text"
                placeholder="username"
                autoComplete="username"
                autoFocus
                required
              />
              <label htmlFor="password">password</label>
              <input
                id="password"
                type="password"
                placeholder="password"
                name="current-password"
                autoComplete="password"
                required
              />
              <button
                id="loginbutton"
                className="btn btn-primary"
                type="submit"
                onClick={_Login}>
                {loading || loadingLogin ? (
                  <span id="buttontext">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                    LOADING...
                  </span>
                ) : (
                  <span id="buttontext">login</span>
                )}
              </button>
            </form>
          </div>
        </div>
        <div className="informations">
          <div>
            <p className="environment">
              if you&apos;re having issues, contact the Support Admin
            </p>
            <p>
              <strong>Fre Timmerman</strong>
            </p>
          </div>
          <div className="text-align-right">
            <a
              id="adminlink"
              className="cta cta-primary"
              href={REACT_APP_API_ADMIN_LOCATION}
              rel="noreferrer"
              target="_blank"
              title="Click to open the administration">
              <i className="fas fa-external-link-alt"></i>Open the
              administration
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
